import React from 'react';
import {useNavigate, Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';

import Images from '../../utilies/Images';

import config from '../../inc/config'
import {isoToDateTime, priceFormat, slugify} from '../../inc/helper';
import { getCookie } from '../../inc/cookies';
import Select from 'react-select';

class CreditCardRequestsModule extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      isLoading: true,

      creditCardPayments: [],
      filterPaymentStatus: '',
      filterAdvertId: '',
      filterId: '',
      filterFullName: '',
      filterAddedStartDate: '',
      filterPaymentStartDate: '',


      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    }
  }

  componentDidMount() {
    this.getCreditRequests(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  filteredCreditRequests(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const query = `limit=${this.state.limit}&page=${currentPage}&filterId=${this.state.filterId}&filterFullName=${this.state.filterFullName}&filterAdvertId=${this.state.filterAdvertId}&filterPaymentStatus=${this.state.filterPaymentStatus}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterPaymentStartDate=${this.state.filterPaymentStartDate}`;

    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + sessionHash}
  };

  const startIndex = 0;
  const endIndex = startIndex + this.state.limit;

  fetch(config.restApi + `creditCardPayments/tr?${query}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const currentCreditRequests = responseData.creditCardPayments.slice(startIndex, endIndex);
          this.setState({isLoading: false, creditCardPayments: currentCreditRequests, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
}

  getCreditRequests(currentPage) {
	  	let sessionHash = getCookie('sessionHash');

      const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `creditCardPayments/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200:
            const currentCreditRequests = responseData.creditCardPayments.slice(startIndex, endIndex);
            this.setState({isLoading: false, creditCardPayments: currentCreditRequests, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
          break;
          case 401:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(this.props.navigate('/home'))
          break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.filteredCreditRequests(this.state.currentPage))
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  filterOnClick = (event) => {
    const query = `filterId=${this.state.filterId}&filterFullName=${this.state.filterFullName}&filterAdvertId=${this.state.filterAdvertId}&filterPaymentStatus=${this.state.filterPaymentStatus}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterPaymentStartDate=${this.state.filterPaymentStartDate}`;
    this.filteredCreditRequests(1, query)
  }

  resetOnClick = (event) => {
    this.setState({
      filterPaymentStatus: '',
      filterAdvertId: '',
      filterId: '',
      filterFullName: '',
      filterAddedStartDate: '',
      filterPaymentStartDate: '',
      currentPage: 1
    }, () => {this.setState({isLoading: true}, () => {this.filteredCreditRequests(this.state.currentPage);this.setState({isLoading: false});});});
  }

  handleKeyPress = (event, page) => {
    if (event.key === 'Enter') {
      const query = `filterId=${this.state.filterId}&filterFullName=${this.state.filterFullName}&filterAdvertId=${this.state.filterAdvertId}&filterPaymentStatus=${this.state.filterPaymentStatus}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterPaymentStartDate=${this.state.filterPaymentStartDate}`;
      this.setState({isLoading: true, currentPage: 1}, () => {this.filteredCreditRequests(this.state.currentPage, query);this.setState({isLoading: false});});
    }
  }

  filterPaymentStatus = (event) => {
    this.setState({filterPaymentStatus: event.target.value});
  }

  getCreditStatusName(paymentStatus) {
    if(paymentStatus === 1){
      return <strong className="font-bold text-warning">İşleme Alındı</strong>
    } else if(paymentStatus === 2){
      return <strong className="font-bold text-green">Başarılı</strong>
    } else if(paymentStatus === 3){
      return <strong className="font-bold text-danger">Başarısız</strong>
    }

    return '-'
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="row custom-container">
        <div className="col-xl-9">
          <div className="ibox">
            <div className="ibox-title">
              <h5 className="text-darkblue">Kredi Başvuruları</h5>
            </div>
            <div className="table-responsive overflow-x-auto">
              <table className="table table-striped table-bordered">
                <thead className="text-center">
                  <tr className="text-darkblue">
                    <th className="align-middle">İlan Görseli</th>
                    <th className="align-middle">İlan Başlığı</th>
                    <th className="align-middle">Başvuru Sahibi</th>
                    <th className="align-middle">İşlemi Yapan</th>
                    <th className="align-middle">İşlem Tutarı</th>
                    <th className="align-middle">Ödeme Tarihi</th>
                    <th className="align-middle">Ödeme Durumu</th>
                    <th className="align-middle">Sonuç</th>
                    <th className="align-middle">Detay</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                {this.state.creditCardPayments.map((creditCardPaymentInfo) =>
                    <tr key={creditCardPaymentInfo.id}>
                      <td className="align-middle">
                        {creditCardPaymentInfo.photos.length > 0 ? <Link className="text-orange text-underline-orange" target='_blank' to={`https://${config.host}/ilan/${slugify(creditCardPaymentInfo.advertTitle)}-${creditCardPaymentInfo.advertId}/detay`}><img alt="İlan Görseli" className="w-md-50" style={{ height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${creditCardPaymentInfo.photos[0].path}`}/></Link>
                        : <Link className="text-orange text-underline-orange" to={`https://${config.host}/ilan/${slugify(creditCardPaymentInfo.advertTitle)}-${creditCardPaymentInfo.advertId}/detay`}><i className="fa fa-camera d-flex justify-content-center align-items-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2' }}></i></Link>}
                        <br /><Link className="text-orange text-underline-orange" to={`/pendingAdvertDetail?id=${creditCardPaymentInfo.advertId}`}><strong className="text-darkblue font-bold">İlan No: #{creditCardPaymentInfo.advertId}</strong></Link><Link className="text-orange text-underline-orange" to={`/creditRequestDetail?id=${creditCardPaymentInfo.id}`}><strong className="text-darkblue font-bold"><br />Kredi No: #{creditCardPaymentInfo.id}</strong></Link>
                      </td>
                      <td className="align-middle">{creditCardPaymentInfo.advertTitle}</td>
                      <td className="align-middle">{creditCardPaymentInfo.memberFullName}</td>
                      <td className="align-middle">{creditCardPaymentInfo.addedFullName}</td>
                      <td className="align-middle">{priceFormat(creditCardPaymentInfo.amount)}</td>
                      <td className="align-middle">{isoToDateTime(creditCardPaymentInfo.paymentDate)}</td>
                      <td className="align-middle">{this.getCreditStatusName(creditCardPaymentInfo.paymentStatusId)}</td>
                      <td className="align-middle">{creditCardPaymentInfo.paymentStatusId === 3 ? <strong className="font-bold text-danger">Başarısız</strong> : creditCardPaymentInfo.paymentStatusId !== 2 ? <strong className="font-bold text-warning">Beklemede</strong> : creditCardPaymentInfo.cancelledBy ? <strong className="font-bold text-danger">İptal Edildi</strong> : <strong className="font-bold text-green">Başarılı</strong>}</td>
                      <td className="align-middle"><Link className="btn btn-outline btn-orange text-center mb-1" to={'/creditCardPaymentDetail?id=' + creditCardPaymentInfo.id} title="Başvuru Detayları"><i className="fa fa-eye"></i></Link></td>
                    </tr>
                  )}
                {this.state.creditCardPayments.length === 0 && <tr><td className="align-middle p-3 text-darkblue" colSpan="9">Kredi Başvuru Listesi Bulunamadı !</td></tr>}
                </tbody>
              </table>
            </div>
            {this.state.creditCardPayments.length !== 0 &&
            <ReactPaginate
              previousLabel={"Geri"}
              nextLabel={"İleri"}
              breakLabel={"..."}  
              pageCount={this.state.pageCount}
              marginPagesDisplayed={1}
              onPageChange={page => this.handlePageClick(page)}
              pageRangeDisplayed={2}
              containerClassName={'pagination'}
              activeClassName={'activePage'}
            />
            }
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
          <div className="ibox">
            <div className="ibox-content text-darkblue p-0">
              <h4 className="collapse-title">
                <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
              </h4>
            </div>
            <div className="collapse" data-parent="#accordion" id="filterCollapse">
              <div className="ibox-content text-darkblue">  
                <strong>İlan No</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterAdvertId: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="İlan No" type="text" value={this.state.filterAdvertId}/>
                <strong>Kredi No</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterId: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Kredi No" type="text" value={this.state.filterId}/>
                <strong>Başvuru Sahibi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Başvuru Sahibi" type="text" value={this.state.filterFullName}/>
                <strong>Ödeme Durumu</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={this.filterPaymentStatus} onKeyDown={this.handleKeyPress} value={this.state.filterPaymentStatus}>
                  <option value="">Seçiniz</option>
                  <option value="1">İşleme Alındı</option>
                  <option value="2">Başarılı</option>
                  <option value="3">Başarısız</option>
                </select>
                <strong>Başlangıç Tarihi</strong>
                <input className="form-control form-inputt mb-2 mt-1" onChange={event => this.setState({filterAddedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedStartDate}/>
                <strong>Ödeme Tarihi</strong>
                <input className="form-control form-input" onChange={event => this.setState({filterPaymentStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterPaymentStartDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2"><i className="fa fa-refresh" onClick={this.resetOnClick}></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" ><i className="fa fa-filter"  onClick={this.filterOnClick}></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
          <div className="ibox">
            <div className="ibox-title text-darkblue">
              <h5><i className="fa fa-filter"></i> Filtrele</h5>
            </div>
            <div className="ibox-content text-darkblue">
              <strong>İlan No</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterAdvertId: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="İlan No" type="text" value={this.state.filterAdvertId}/>
              <strong>Kredi No</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterId: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Kredi No" type="text" value={this.state.filterId}/>
              <strong>Başvuru Sahibi</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Başvuru Sahibi" type="text" value={this.state.filterFullName}/>
              <strong>Ödeme Durumu</strong>
              <select className="form-control form-input mb-2 mt-1" onChange={this.filterPaymentStatus} onKeyDown={this.handleKeyPress} value={this.state.filterPaymentStatus}>
                <option value="">Seçiniz</option>
                  <option value="1">İşleme Alındı</option>
                  <option value="2">Başarılı</option>
                  <option value="3">Başarısız</option>
              </select>
              <strong>Başlangıç Tarihi</strong>
              <input className="form-control form-inputt mb-2 mt-1" onChange={event => this.setState({filterAddedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedStartDate}/>
              <strong>Ödeme Tarihi</strong>
              <input className="form-control form-input" onChange={event => this.setState({filterPaymentStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterPaymentStartDate} />
              <div className="d-flex justify-content-end">
                <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                <button className="btn btn-orange mt-3"  onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function CreditCardRequests(props) {
  return (
    <CreditCardRequestsModule navigate={useNavigate()} />
  )
}